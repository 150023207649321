

const initialState = {
    selectedProduct: [],  // This will store the currently selected product
};

export const Reducer = (state = initialState, action) =>{
    switch (action.type){
        case "ADD" : 
        return { ...state, selectedProduct: [action.payload] };
            // return {...state, empty : viewItem}
        default :
            return state
    }
}